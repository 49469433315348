<script lang="ts">
	import { page } from '$app/stores';

	import { MARKETING_COPY } from '../../../copy.js';
	import StripeSupportedPaymentProcessors from './StripeSupportedPaymentProcessors.svelte';
</script>

<div
	data-testid="landing-page-cta-card"
	class="mx-auto flex w-full max-w-md flex-col items-center transition-all tablet:text-xl"
>
	<!-- <img
		alt="Tickrr logo"
		src="/logo-dark-text.png"
		class="mb-4 w-24 border-4 border-primary-500 rounded-container-token"
	/> -->
	<span class="mb-2">Tickrr Pro | Monthly</span>
	<span class="mb-2 font-sans text-5xl font-black text-surface-50 tablet:text-6xl"
		>14 days free</span
	>
	<span class="mb-4">Then $39 USD per month</span>

	<a
		href={$page.data.profile ? '/checkout' : '/subscribe'}
		class="variant-filled btn w-full max-w-xs flex-col gap-y-2 py-4 font-sans font-bold"
	>
		<span>Start your free trial</span>
		<!-- <StripeSupportedPaymentProcessors /> -->
	</a>
	<StripeSupportedPaymentProcessors classes="mt-3" />

	<div class="mt-8">
		<span class="mx-auto mb-4 block text-center">Features include:</span>
		<ul class="list">
			{#each MARKETING_COPY.features as f (f)}
				<li>
					<iconify-icon inline={true} icon={f.icon} />
					<!-- eslint-disable-next-line svelte/no-at-html-tags -->
					<span>{@html f.label}</span>
				</li>
			{/each}
		</ul>
	</div>
</div>
