<script>
	import { fadeInOnIntersect } from '../../../../actions';
	import PulsatingSphere from '../../elements/animated/PulsatingSphere.svelte';
	import Cta from '../stripe/StripeCTA.svelte';

	export let isTransitionEnabled = true;
	export let imgClasses = '';
	export let ctaClasses = '';
</script>

<section
	data-testid="cta-subscribe-page"
	class="mx-auto w-full max-w-page px-6 py-4 tablet:p-8"
	use:fadeInOnIntersect={{
		duration: 2000,
		enabled: isTransitionEnabled,
		translate: 'translate-y-40'
	}}
>
	<div
		class="light-effect"
		style="bottom: 0%; right: 22.5%; transform: rotate(-45deg) translateY(-40%);"
	></div>
	<div
		class="light-effect"
		style="bottom: 0%; right: 30%; transform: rotate(-65deg) translateY(-40%);"
	></div>

	<!-- HEADER TEXT -->
	<div class="w-full max-w-7xl tablet:mx-auto">
		<div class="mb-1 flex items-center gap-x-2 text-primary-500">
			<PulsatingSphere size="h-2 w-2" />
			<span class="whitespace-nowrap font-mono"> Join today </span>
		</div>

		<div
			class="flex w-full max-tablet:flex-col max-tablet:gap-y-3 tablet:items-start tablet:justify-between tablet:gap-x-4"
		>
			<h2 class="text-5xl font-black text-surface-50 tablet:w-full">Sign up for Tickrr!</h2>

			<p
				class="font-mono text-xl text-surface-100 tablet:w-full tablet:max-w-xl tablet:text-right"
			>
				Start your free trial today and drive your profits to the moon.
			</p>
		</div>
	</div>

	<!-- CTA -->
	<div class="mx-auto w-full max-w-7xl max-tablet:my-8 tablet:my-20 {ctaClasses}">
		<Cta />
	</div>

	<!-- IMAGE -->
	<div class="flex justify-center overflow-hidden max-tablet:h-36 tablet:h-60 laptop:h-80">
		<div
			class="
			relative
			after:absolute after:inset-x-[10%] after:inset-y-0 after:z-10 after:h-full
			after:bg-gradient-to-b after:from-transparent after:via-surface-900/20 after:to-surface-900
			"
		>
			<img
				alt="A screenshot of the Tickrr chart page on a Macbook Pro"
				src="/images/landingpage/macbook-mockup.png"
				class="object-cover max-tablet:h-[268px] tablet:h-[480px] laptop:h-[640px] {imgClasses}"
			/>
		</div>
	</div>
</section>
